import React from 'react';

import { Helmet } from "react-helmet";

const TermsOfServices = () => {
    
      return (
    
    <>
        <Helmet>
        <meta charset="utf-8" />
        <title>Check Out The Terms Of Service For The 2.0 Conferences</title>
        <base href="/" />
        <meta name="robots" content="index"></meta>
        <meta name="description" content=" Explore the 2.0 Conferences' terms and conditions covering website use, policies, and participant guidelines for events in the USA, UAE, and Singapore. " />
        <meta property="og:title" content="Check Out The Terms Of Service For The 2.0 Conferences" />
        <meta property="og:description" content="Explore the 2.0 Conferences' terms and conditions covering website use, policies, and participant guidelines for events in the USA, UAE, and Singapore." />

        </Helmet>
        <section className="home_banner " style={{paddingBottom: '16px'}}>
            <figure className="home_figure">
                <img src="https://the2conf.com/the2conf-Terms-Of-Service.jpg" alt="Terms-Of-Service" width="100%" />
                <figcaption className="home_figure_caption">
                    <div className="container">
                        <div className="banner_text">
                            <h1>Terms Of Service </h1>
                        </div>
                    </div>
                </figcaption>
            </figure>
        </section>
  <section className="policy_services exhibit_enquire_banner across_sectors_banner gallery_banner_caption">
  <div className="container" style={{fontSize : '16px' }}>
          <div className="content">
            <div className="wrapper-1">
              <div className="wrapper-2" style={{marginTop : '16px' }}>
          <p>
            <strong>1. Who we are:</strong>
          </p>
          <p>
          1.1 We are The 2.0 Conferences (“we,” “us,” and “our”).
          </p>
          <p>
            <strong>2. What we do:</strong>
          </p>
          <p>
          2.1 We conduct various conferences such as Health 2.0 Conference, Education 2.0 Conference, CXO 2.0 Conference, Founders 2.0 Conference, and FUELD Conference in Dubai, USA, and Singapore. Our objective is to bring leaders on a global stage that encourages innovation and networking and facilitates the exchange of fresh ideas.

          </p>
          <p>
            <strong>3. Agreement between you and us            </strong>
          </p>
          <p>
          3.1 These terms and conditions (referred to as the "Terms") govern your access to and use of the www.the2conf.com website, as well as your participation in any online surveys and questionnaires associated with the Conference (collectively referred to as the "Website").
          </p>
          <p>
          3.2 Please review this document carefully. By visiting, accessing, using, or submitting content to the Website, you confirm that you have read, understood, and agree to be legally bound by these Terms. If you do not agree with these Terms, please discontinue your use of the Website immediately.
          </p>
          <p>
            <strong>4. Scope</strong>
          </p>
          <p>
           4.1 These Terms govern the relationship between us and:
          </p>
          <p>
            4.1.1 visitors and users of the Conference Websites and their related subdomains;
          </p>
          <p>
           4.1.2 participants of the online surveys relating to the Conference.
          </p>
          <p>
            <strong>5. Privacy policy</strong>
          </p>
          <p>
          5.1 These Terms also incorporate and reference the www.the2conf.com/privacy-policy, which outlines the types of data collected, stored, shared, and processed through the Website. It also explains how this information is used, the parties with whom it may be shared, and your rights regarding your personal data.
          </p>
          <p>
            <strong>6. Changes To The Policy</strong>
          </p>
          <p>
          6.1 These Terms take effect immediately upon publication and remain in force until updated or replaced by a revised version on the Website. We reserve the right to amend these Terms at any time to comply with legal, security, or regulatory requirements, or to align with changes in our services or business operations. Any modifications will be published on www.the2conf.com.
          </p>
          <p>
            <strong>7. Accessing The Website</strong>
          </p>
          <p>
          7.1 When using the Website and its related subdomains, you agree not to:
          </p>
          <p>
          7.1.1 Violate any applicable laws, regulations, or legal requirements.
          </p>
          <p>
            7.1.2 Use the Website (or any part of it) for any unlawful purposes and agree to comply with all applicable legal standards.

          </p>
          <p>
          7.1.3 Share or promote content that encourages illegal activities, violence, or harm toward any group, community, or individual.
          </p>
          <p>
          7.1.4 Upload, transmit, or introduce any harmful software, including but not limited to viruses, Trojan horses, worms, or other malicious code.
          </p>
          <p>
          7.1.5 Tamper with or disable any security features of the Website or compromise the security of your account or any other user’s account, including unauthorized access.
          </p>
          <p>
          7.1.6 Interfere with the functionality of the Website, overload the system, or engage in activities like denial-of-service attacks or other actions disrupting service.

          </p>
          <p>
          7.1.7 Use automated tools, bots, or manual processes to scrape, extract, or collect data or content from the Website without proper authorization.
          </p>
          <p>
          7.1.8 Engage in actions like "framing" or "mirroring" to duplicate the appearance or functionality of the Website.
          </p>
          <p>
          7.1.9 Remove, alter, or conceal any copyright, trademark, or proprietary notices included on the Website.
          </p>
          <p>7.1.10 Alter, replicate, create derivative works from, or store a significant portion of the Website's content or technology without prior written permission, unless legally permitted.</p>
          <p>
          7.1.11 Access or use the Website in ways that may:
          </p>
          <p>
          (i) Interrupt, degrade, or damage its functionality or performance.
          </p>
          <p>
          (ii) Share or promote misleading, defamatory, abusive, obscene, harmful, offensive, or otherwise inappropriate content.
          </p>
          <p>
          (iii) Tamper with the Website's operational integrity or effectiveness.

          </p>
          <p>
          (iv) Violate the rights of any individual, firm, or entity, including intellectual property, privacy, or confidentiality rights.
          </p>
          <p>
          7.1.12 Attempt unauthorized access to restricted areas or components of the Website.
          </p>

        <p>7.1.13 Copy, reproduce, or distribute content from the Website without explicit written consent. </p>
        <p>7.1.14 Modify or alter the Website’s structure, features, or content, except as necessary for its intended use.</p>
        <p>7.1.15 Reverse engineer, decompile, or attempt to access the source code, underlying technology, or foundational information related to the Website unless permitted by law.
        </p>
          <p>
            <strong>8. Disclaimer</strong>
          </p>
          <p>
          8.1 While we strive to ensure the accuracy of the information presented on the Website, we make no express or implied guarantees regarding its correctness. The Website will be developed, maintained, and operated with reasonable skill and care. We will take appropriate steps to address any known issues in a timely manner. However, the Website, including all materials, information, software, products, and services available through it, is provided on an “as is” and “as available” basis for informational and personal use only. We do not guarantee uninterrupted or error-free access to the Website.
          </p>
          <p>
          8.2 To the maximum extent allowed by applicable law, we disclaim all express or implied warranties and conditions related to the Website and all materials, information, software, products, and services offered on or through it, whether provided directly by us or on our behalf. This includes any reliance on results, information, or conclusions drawn from the content on the Website. By accepting these Terms, you acknowledge that you have not relied on any statements, representations, or warranties not explicitly included in these Terms and waive any claims related to representations not expressly stated here.
          </p>
          <p>
          8.3 To the fullest extent permitted by law, we disclaim all forms of warranties, conditions, and duties, whether expressed or implied. This includes, but is not limited to, warranties of merchantability, fitness for a particular purpose, non-infringement, accuracy, security, reliability, or completeness. No implied warranties arising from usage, trade practices, or prior dealings are guaranteed.
          </p>
          <p>
          8.4 You acknowledge that we cannot guarantee and are not responsible for the timeliness, security, performance, or privacy of the Website, including any information you provide or obtain from it.

          </p>
          <p>8.5 You acknowledge and agree that The 2.0 Conferences may use images or videos of past attendees from its events for marketing purposes, including future promotional materials, without requiring additional approval from you.</p>

          <p>
            <strong>9. Liability</strong>
          </p>
          <p>
          9.1 To the fullest extent allowed by applicable law:
          </p>
          <p>
          9.1.1 we and our related companies shall not be liable to you whether, in contract, tort (including for negligence), misrepresentation, restitution, or otherwise for any loss of profits; loss of sales or business; loss of agreements or contracts; loss of anticipated savings or income; loss of opportunity; loss of or damage to goodwill; loss of use or corruption of software, data or information; and/or similar losses or pure economic loss, or for any indirect or consequential loss, costs, damages, charges or expenses however arising under or in connection with the performance or contemplated performance of the Terms, even if we had been advised of the possibility of same in advance; and
          </p>

            <p>9.1.2 subject to paragraph 9.1.1, our total aggregate liability in contract, tort (including negligence), misrepresentation, restitution, or otherwise, arising under or in connection with the performance or contemplated performance of the Terms is limited to $50.00.</p>

            <p>9.2 Nothing in these Terms purports to exclude or limit liability for any fraudulent statement or act or in respect of any liability that cannot be excluded or limited under applicable law.</p>

          <p>
            <strong>10. Indemnity</strong>
          </p>
          <p>
          10.1 You agree to protect, indemnify, and hold us, along with our affiliates, subsidiaries, joint ventures, third-party service providers, and their respective employees, contractors, agents, officers, and directors, free from any claims, liabilities, damages, losses, costs, or expenses, including legal fees. This obligation arises from any violation of these Terms by you or any liability resulting from your use or misuse of the Website, including instances where someone else accesses the Website using your internet account.
          </p>
          <p>
            <strong>11. Intellectual Property</strong>
          </p>
          <p>
            11.1 We either own or hold the necessary licenses for all intellectual property rights associated with the Website. You acknowledge that all copyrights, trademarks, and other intellectual property rights in the materials and content available on the Website will remain the property of either us or our licensors. Your use of these materials is permitted only when expressly authorized by us. All rights not explicitly granted in relation to the Website and its content are reserved. You agree not to copy, reproduce, publish, republish, distribute, store, modify, adapt, translate, create derivative works from, summarize, scan, license, sell, frame, scrape, grant, or attempt to grant any rights to or otherwise make available any content from the Website without prior permission.
          </p>
          <p>
            <strong>12. Termination</strong>
          </p>
          <p>
          12.1 We reserve the right, in our sole discretion, to terminate or block your access to all or part of the Websites, with or without notice, for any reason or no reason, including without limitation your violation of these Terms.
          </p>
          <p>
            <strong>13. Statutory rights</strong>
          </p>
          <p>
          13.1 Nothing in these Terms affects your statutory rights as a consumer.
          </p>
          <p>
            <strong>14. Cancellations and Refunds</strong>
          </p>
          <p>
            <strong>Eligibility For Refunds:</strong> Refund requests must be submitted within 7 calendar days from the date of completing your registration. If requested within this period, a full refund will be issued without the need to provide any specific reason.
          </p>

          <p>
            <strong>Restrictions On Late Refunds:</strong> After the initial 7-day window, all registration fees become non-refundable. Exceptions may be considered only in rare, exceptional cases beyond your control. Such requests will be reviewed individually. To be considered, you must provide complete and verifiable documentation supporting your claim. Please note that submitting a refund request does not guarantee approval, as all decisions regarding refunds will be made solely at our discretion and are final.
          </p>

          <p>
            <strong>15. Governing Law And Jurisdiction</strong>
          </p>
          <p>
            15.1 The interpretation, enforcement, and implementation of these Terms, along with any non-contractual obligations related to or arising from them, will be governed by and construed in accordance with the laws of the UAE (specifically the Emirate of Dubai) for UAE-based events and the USA for USA-based events. Any disputes arising from or connected to these Terms, whether contractual or non-contractual, will be subject to the jurisdiction of the UAE courts for UAE events and the USA courts for USA events, without consideration of conflict of law principles.
          </p>
            <p>15.2 All parties consent to the exclusive jurisdiction of the courts located in Delaware, USA, for resolving disputes under these Terms.</p>

            <p>15.3 Nothing in these Terms restricts our right to initiate legal proceedings in other jurisdictions or seek interim, protective, or provisional relief in courts outside the jurisdictions mentioned above if deemed necessary.
            </p>

          <p>
            <strong>16. Some Final Terms</strong>
          </p>
          <p>
          16.1 These Terms, including our Privacy Policy, represent the complete agreement between you and us regarding the matters covered here and override any previous written or verbal agreements on the same subject. By accepting these Terms, you confirm that you have not relied on any statements or representations not explicitly stated within this document. No claims can be made regarding any representation not included here. However, this clause does not limit liability for fraudulent statements or actions.
          </p>
          <p>
          16.2 If any part of these Terms is determined to be partially or entirely unenforceable by a court of competent authority, both parties agree that the court should adjust the affected section where possible to ensure enforceability while maintaining the original intent. If modification is not feasible, the unenforceable portion will be disregarded, and the remaining Terms will continue to apply in full force.
          </p><p>

          16.4 These Terms are personal to you. You agree not to assign or transfer your rights or obligations under these Terms to anyone else without receiving our prior written consent. We may assign or transfer these Terms without your consent by providing you with notice. </p>
          <p>
            16.5 You and we agree that no agency, partnership, joint venture, or employment is created as a result of these Terms and you do not have any authority of any kind to bind us in any respect. </p>
            <p>
                16.6 You agree that the only way you can provide us with a legal notice is at the address(s) set out in Part 1 above. </p>
            

            <p>16.7 To the extent allowed by law, the English language version of these Terms is binding, and any translations are for convenience only. </p>


            <p>16.8 In these Terms: </p>


            <p>16.8.1 a reference to these Terms includes all of its parts, and includes any amendment to or replacement of them; </p>


            <p>16.8.2 headings are for reference purposes only and do not form part of these Terms; </p>


            <p>16.8.3 a reference to a statute, code, or other law includes regulations and other instruments under it and consolidations, amendments, re-enactments, or replacements of any of them; </p>


            <p>16.8.4 the singular includes the plural and vice versa; and </p>

            <p>
            16.8.5 “includes,” “including,” “for example,” “such as,” and similar terms are not words of limitation. </p>

            <p>16.9 If you are a consumer, nothing in these Terms excludes any of your applicable consumer or other statutory legal rights that cannot be waived. </p>

        </div>
      </div>
    </div>
    </div>
  </section>
    </>
  )
}

export default TermsOfServices